$widescreen: 2599px;
$desktop: 1799px;
$laptop: 1280px;
$tablet: 991px;
$mobile: 767px;
$phone: 500px;

#app-media {
    position: relative;

    #news {
        margin: auto;
        padding: 10rem 8rem;
        max-width: 1920px;

        @media(max-width: $desktop) {
            padding: 8rem 4rem;
            max-width: 1499px;
        }

        @media(max-width: $laptop) {
            padding: 5rem 2rem;
            max-width: 1099px;
        }

        .masonry-grid {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-left: -15px;
            width: auto;

            .masonry-grid-column {
                padding-left: 15px;
                background-clip: padding-box;
                margin-bottom: 15px;

                .grid-item {
                    margin-bottom: 15px;
                }
            }
        }


        .col {
            &.featured {
                position: relative;
                width: 100% !important;
                margin-bottom: 15px;

                .card-overlay {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 40%;
                    width: 420px;


                    @media(max-width: $tablet) {
                        bottom: 0;
                        top: inherit;
                        transform: none;
                        position: relative;
                        width: 100%;
                        background-color: #dff12f;
                    }

                    .headline-wrapper {
                        padding: 1rem 0 1rem 2rem;

                        @media(max-width: $tablet) {
                            padding: 1rem;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            top: -2rem;
                            left: -7rem;
                            right: 0;
                            height: 100%;
                            width: 120%;
                            border: 1px solid #dff12f;
                            padding: 7.4rem;
                            transform: skew(-40deg);

                            @media(max-width: $tablet) {
                                content: none;
                            }
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            z-index: 0;
                            top: 0;
                            left: -3rem;
                            right: 0;
                            height: 100%;
                            width: 120%;
                            background-color: #dff12f;
                            transform: skew(-40deg);

                            @media(max-width: $tablet) {
                                content: none;
                            }
                        }

                        .headline {
                            position: relative;
                            z-index: 1;

                            @media(max-width: $tablet) {
                                h6 {
                                    display: none;
                                }
                            }

                            h1 {
                                font-weight: 400;
                                font-size: 2rem;
                                line-height: 1;

                                @media(max-width: $tablet) {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }

            .card {
                overflow: hidden;
                position: relative;
                border: 0;

                .card-image {
                    background-color: #c2c2aa;
                    overflow: hidden;

                    img {
                        transition: all 0.9s ease-in-out;
                        transform: scale(1.02);
                    }

                    &.scale-off {
                        img {
                            transform: none;
                        }
                    }

                    &.holder {
                        padding: 1.6rem;

                        img {
                            opacity: 0.2;
                        }

                    }
                }

                .card-wrapper {
                    padding: 0.6rem 1rem;
                    background-color: #dff12f;
                    position: relative;
                    z-index: 1;

                    a {
                        color: #000;
                        text-decoration: none;
                    }

                    .card-title {

                        text-transform: uppercase;
                        font-size: 19px;
                        margin-bottom: 0;
                        line-height: 1.2;


                        @media(max-width: $mobile) {
                            position: relative;
                            padding: 0.2rem;
                            font-size: 15px;
                        }
                    }

                    .btn {
                        font-size: 14px;
                        padding: 0.3rem 0.7rem;

                        &:hover {
                            border: 1px solid #000;
                        }
                    }
                }

                &:hover {
                    .card-image img {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

.modal {
    .modal-content {
        overflow: hidden;
    }

    .modal-header {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        border: 0;
        padding: 0.8rem;

        @media(max-width: $tablet) {
            padding: 0.6rem;
        }

        &:before {
            position: absolute;
            content: '';
            right: -105%;
            top: -60%;
            height: 110%;
            width: 120%;
            background-color: #dff12f;
            transform: rotate(45deg) scale(3);
            opacity: 0.8;

            @media(max-width: $tablet) {
                transform: rotate(45deg) scale(2.4);
                right: -70%;
            }
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }

    .btn-close {
        position: relative;
        font-size: 15px;
        opacity: 1;

        @media(max-width: $tablet) {
            font-size: 13px !important;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .modal-body {
        padding: 0 !important;

        .article-content {
            position: relative;
            padding: 30px;

            h1 {
                line-height: 1;
            }

            @media(max-width: $mobile) {
                padding: 20px;
            }

        }

        .content-block {
            padding: 2rem 1rem;

            @media(max-width: $mobile) {
                padding: 2rem 0;
            }
        }

        .footer-image {
            position: relative;
        }

        .draw-lines {
            opacity: 0.3;
        }
    }
}