#app-nav {
    position: absolute;
    padding: 20px 10px;
    width: 100%;
    z-index: 2;

    @media(max-width: $laptop) {
        padding: 10px 0 10px 0;
    }

    .container-fluid {

        .navbar-brand {
            width: 72px;
            padding: 3px 0 0 0;
            margin-right: 2rem;

            @media(max-width: $laptop) {
                width: 62px;
                margin-right: 1rem;
            }

            @media(max-width: $mobile) {
                width: 38px;
                margin-right: 0.3rem;
            }
        }

        .navbar-nav {
            a {
                position: relative;
                color: $black;
                text-transform: uppercase;
                margin-right: 1rem;
                transition: color 0.3s ease-in-out;

                @media(max-width: $laptop) {
                    font-size: 1rem;
                    margin-right: 0.5rem;
                }

                @media(max-width: $mobile) {
                    margin-right: 0;
                    font-size: 0.9rem;
                }

                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;
                    height: 2px;
                    width: 0;
                    background-color: $theme;
                    transition: width 0.2s ease-in;
                }

                &:after {
                    bottom: 1px;
                }

                &:before {
                    top: 1px;
                }

                &.active,
                &:hover {
                    color: $theme;

                    &:after,
                    &:before {
                        width: 100%;
                    }
                }
            }
        }

        .social-block {
            @media(max-width: $mobile) {
                display: none;
            }

            a {
                background-color: $black;
                margin-left: 20px;
                padding: 5px 5px 3px 5px;
                border-radius: 6px;

                @media(max-width: $mobile) {
                    font-size: 0.8rem;
                    margin-left: 10px;
                }

                i {
                    color: $body-bg;
                    transition: color 0.3s ease-in;
                }

                &:hover {
                    background-color: $theme;

                    i {
                        color: $black;
                    }
                }
            }
        }
    }

    &.light {
        .container-fluid {

            .navbar-nav {
                a {
                    color: $white;
                }
            }

            .social-block {
                a {
                    background-color: $white;

                    i {
                        color: $black;
                    }
                }
            }
        }
    }
}